/* eslint-disable no-loop-func */
import React, { Component } from "react";
import Chart from "react-apexcharts";
import { CardHeader } from "reactstrap";
import allData from "../../../utils/static/total-data-min.json";
const marginTop = 0;
class GraphComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			graphTitle: "availability",
			series: [],
			options: {
				chart: {
					id: "survey-analysis-chart"
				},
				dataLabels: {
					enabled: false
				},
				colors: ["#b3cde0", "#83d0c9", "#e3c9c9", "#ff8b94", "#8c9da9", "#8874a3", "#f1c27d"],
				labels: ["Dec", "Jan", "Feb", "Mar"],
				legend: {
					show: true,
					inverseOrder: false,
					fontSize: "14px",
					horizontalAlign: "center",
					position: "bottom",
					labels: {
						useSeriesColors: true
					},
					markers: {
						size: 0
					},
					// formatter: function(seriesName, opts) {
					// 	return ["\n\r", seriesName, ":", "\n\r", opts.w.globals.series[opts.seriesIndex]];
					// },
					itemMargin: {
						vertical: 3
					}
				},
				plotOptions: {
					bar: {
						horizontal: false
					},
					radialBar: {
						endAngle: 270,
						hollow: {
							margin: 5,
							size: "30%",
							background: "transparent"
						},
						dataLabels: {
							show: true,
							name: {
								show: true
							},
							value: {
								show: true
							}
						}
					}
				},
				responsive: [
					{
						breakpoint: 480,
						options: {
							legend: {
								show: true
							}
						}
					}
				],
				theme: {
					mode: "light",
					palette: "palette5"
				},
				xaxis: {
					categories: ["Dec", "Jan", "Feb", "Mar"]
				},
				yaxis: {
					show: true,
					title: {
						text: "",
						style: {
							fontSize: "14px",
							fontFamily: "Arial",
							fontWeight: 600,
							cssClass: "apexcharts-yaxis-title"
						}
					}
				}
			},
			allData: null,
			selectedData: null,
			isDistrictMap: true,
			topTab: "stock",
			leftTab: null,
			lastClickedPolygon: null,
			isVoronoiSelected: false,
			graph: null,
			topAttribute: "availability",
			leftAttribute: null,
			graphIsRatio: false,
			selectedDistrict: null,
			marginTop: marginTop
			// graphType: "line"
		};

		this.topTabToAttribute = {
			stock: "availability",
			"units given out": "volume",
			"stock out": "stockOut",
			null: null
		};
		this.leftTabToAttribute = {
			population: "population",
			"all products": "allProductsSold",
			"sec level": "sec",
			"foot traffic": "footTraffic",
			accessibility: "accessibility",
			null: null
		};
	}

	componentWillMount() {
		try {
			this.setState({ allData: allData, selectedData: allData }, () => {
				this.prepareDataForDistrictsGraph();
			});
		} catch (e) {
			console.error(e);
		}
	}

	componentDidUpdate() {
		try {
			let {
				isDistrictMap,
				topTab,
				leftTab,
				lastClickedPolygon,
				isVoronoiSelected,
				selectedDistrict,
				selectedData
			} = this.state;
			if (this.props.selectedDistrict !== selectedDistrict) {
				selectedDistrict = this.props.selectedDistrict;
				if (selectedDistrict) selectedData = { [selectedDistrict]: this.state.allData[selectedDistrict] };
				else selectedData = this.state.allData;
				this.setState({ selectedData, selectedDistrict }, this.chooseGraphType);
			}
			if (this.props.isDistrictMap !== isDistrictMap) {
				isDistrictMap = this.props.isDistrictMap;
				this.setState({ isDistrictMap }, this.chooseGraphType);
			} else if (this.props.topTab !== topTab) {
				topTab = this.props.topTab;
				this.setState({ topTab, topAttribute: this.topTabToAttribute[topTab] }, this.chooseGraphType);
			} else if (this.props.leftTab !== leftTab) {
				leftTab = this.props.leftTab;
				this.setState({ leftTab, leftAttribute: this.leftTabToAttribute[leftTab] }, this.chooseGraphType);
			} else if (this.props.isVoronoiSelected !== isVoronoiSelected) {
				isVoronoiSelected = this.props.isVoronoiSelected;
				if (this.props.lastClickedPolygon !== lastClickedPolygon) {
					lastClickedPolygon = this.props.lastClickedPolygon;
					this.setState({ isVoronoiSelected, lastClickedPolygon }, this.chooseGraphType);
				} else this.setState({ isVoronoiSelected }, this.chooseGraphType);
			} else if (this.props.lastClickedPolygon !== lastClickedPolygon) {
				lastClickedPolygon = this.props.lastClickedPolygon;
				if (this.props.isVoronoiSelected !== isVoronoiSelected) {
					isVoronoiSelected = this.props.isVoronoiSelected;
					this.setState({ isVoronoiSelected, lastClickedPolygon }, this.chooseGraphType);
				} else this.setState({ lastClickedPolygon }, this.chooseGraphType);
			}
		} catch (e) {
			console.error(e);
		}
	}

	chooseGraphType = () => {
		if (this.state.isDistrictMap || !this.state.isVoronoiSelected) this.prepareDataForDistrictsGraph();
		else this.prepareDataForSingleFacility();
	};

	prepareDataForDistrictsGraph = () => {
		try {
			let { topAttribute, leftAttribute } = this.state;
			if (!topAttribute && !leftAttribute) return this.stockGraph();
			else if (topAttribute === "availability") return this.stockGraph();
			else if (topAttribute === "volume") return this.unitsGivenOutGraph();
			else if (topAttribute === "stockOut") return this.stockOutGraph();
			else if (leftAttribute === "population") return this.populationGraph();
			else if (leftAttribute === "allProductsSold") return this.allProductsSoldGraph();
			else if (leftAttribute === "footTraffic") return this.footTrafficGraph();
			else if (leftAttribute === "sec") return this.secGraph();
			else if (leftAttribute === "accessibility") return this.accessibilityGraph();
		} catch (e) {
			console.error(e);
		}
	};

	prepareDataForSingleFacility = () => {
		try {
			let { topAttribute, leftAttribute } = this.state;
			if (!topAttribute && !leftAttribute) return this.stockGraph();
			else if (topAttribute === "availability") return this.stockGraph();
			else if (topAttribute === "volume") return this.unitsGivenOutGraph();
			else if (topAttribute === "stockOut") return this.stockOutGraph();
			else if (leftAttribute === "population") return this.populationGraph();
			else if (leftAttribute === "allProductsSold") return this.allProductsSoldGraph();
			else if (leftAttribute === "footTraffic") return this.footTrafficGraph();
			else if (leftAttribute === "sec") return this.secGraph();
			else if (leftAttribute === "accessibility") return this.accessibilityGraph();
		} catch (e) {
			console.error(e);
		}
	};

	stockGraph = () => {
		try {
			let {
				series,
				selectedData,
				isDistrictMap,
				leftAttribute,
				graphTitle,
				graphIsRatio,
				lastClickedPolygon
			} = this.state;
			let options = JSON.parse(JSON.stringify(this.state.options));
			let keys = Object.keys(selectedData);
			series = [];
			let data = [];
			let topData = null;
			let leftData = null;
			graphIsRatio = false;
			let mData = {};
			for (const key of keys) {
				if (!(selectedData[key].id && selectedData[key].id === key)) continue;
				if (isDistrictMap || lastClickedPolygon === null) mData = selectedData[key];
				if (!isDistrictMap && lastClickedPolygon !== null)
					mData = selectedData[key].locations[lastClickedPolygon];
				topData = mData.data["availability"];
				leftData = mData.data[leftAttribute];
				let name = mData.name ? mData.name : mData.data.name ? mData.data.name : "No name";
				let words = name.split(" ");
				words = words.map(word => {
					return word[0].toUpperCase() + word.slice(1);
				});
				words = words.join(" ");
				if (!isDistrictMap) graphTitle = words;
				else graphTitle = "All Districts";
				if (leftAttribute === "population") {
					data = topData.map(n => {
						return leftData !== 0 ? Math.round((n / leftData + Number.EPSILON) * 1000) : 0;
					});
					graphTitle = "stock / 1000 population : " + graphTitle;
				} else if (leftAttribute === "allProductsSold") {
					data = topData.map((n, i) => {
						return leftData[i] !== 0
							? Math.round((n / leftData[i] + Number.EPSILON) * 1000) / 10 //* 100
							: 0;
					});
					graphTitle = "stock as % of all products given out : " + graphTitle;
				} else if (leftAttribute === "footTraffic") {
					data = topData.map((n, i) => {
						return leftData[i] !== 0 ? Math.round((n / leftData[i] + Number.EPSILON) * 1000) : 0;
					});
					graphTitle = "stock / 1000 foot traffic : " + graphTitle;
				} else if (leftAttribute === "sec") {
					return this.secGraph();
				} else if (leftAttribute === "accessibility") {
					return this.accessibilityGraph();
				} else {
					data = topData;
					graphTitle = "stock : " + graphTitle;
				}
				series.push({ name: words, data: data });
			}
			options.yaxis.title.text = graphTitle.split(" : ")[0];
			let graph = <Chart options={options} series={series} type={"bar"} height="150%" />;
			this.setState({ graph: null, graphIsRatio, graphTitle }, () => this.setState({ graph }));
		} catch (e) {
			console.error(e);
		}
	};

	unitsGivenOutGraph = () => {
		try {
			let { series, selectedData, isDistrictMap, leftAttribute, graphTitle, lastClickedPolygon } = this.state;
			let options = JSON.parse(JSON.stringify(this.state.options));
			let keys = Object.keys(selectedData);
			series = [];
			let data = [];
			let topData = null;
			let leftData = null;
			let mData = {};
			for (const key of keys) {
				if (!(selectedData[key].id && selectedData[key].id === key)) continue;
				if (isDistrictMap || lastClickedPolygon === null) mData = selectedData[key];
				if (!isDistrictMap && lastClickedPolygon !== null)
					mData = selectedData[key].locations[lastClickedPolygon];
				topData = mData.data["volume"];
				leftData = mData.data[leftAttribute];
				let name = mData.name ? mData.name : mData.data.name ? mData.data.name : "No name";
				let words = name.split(" ");
				words = words.map(word => {
					return word[0].toUpperCase() + word.slice(1);
				});
				words = words.join(" ");
				if (!isDistrictMap) graphTitle = words;
				else graphTitle = "All Districts";
				if (leftAttribute === "population") {
					data = topData.map(n => {
						return leftData !== 0 ? Math.round((n / leftData + Number.EPSILON) * 1000) : 0;
					});
					graphTitle = "units given out / 1000 population : " + graphTitle;
				} else if (leftAttribute === "allProductsSold") {
					data = topData.map((n, i) => {
						return leftData[i] !== 0
							? Math.round((n / leftData[i] + Number.EPSILON) * 1000) / 10 //* 100
							: 0;
					});
					graphTitle = "units given out as % of all products given out : " + graphTitle;
				} else if (leftAttribute === "footTraffic") {
					data = topData.map((n, i) => {
						return leftData[i] !== 0 ? Math.round((n / leftData[i] + Number.EPSILON) * 1000) : 0;
					});
					graphTitle = "units given out / 1000 foot traffic : " + graphTitle;
				} else if (leftAttribute === "sec") {
					return this.secGraph();
				} else if (leftAttribute === "accessibility") {
					return this.accessibilityGraph();
				} else {
					data = topData;
					graphTitle = "units given out : " + graphTitle;
				}
				series.push({ name: words, data: data });
			}
			options.yaxis.title.text = graphTitle.split(" : ")[0];
			let graph = <Chart options={options} series={series} type={"scatter"} height="150%" />;
			this.setState({ graph: null, graphTitle }, () => this.setState({ graph }));
		} catch (e) {
			console.error(e);
		}
	};

	stockOutGraph = () => {
		try {
			let {
				series,
				selectedData,
				isDistrictMap,
				leftAttribute,
				graphTitle,
				graphIsRatio,
				lastClickedPolygon
			} = this.state;
			let options = JSON.parse(JSON.stringify(this.state.options));
			let keys = Object.keys(selectedData);
			series = [];
			let data = [];
			let topData = null;
			let leftData = null;
			graphIsRatio = false;
			let mData = {};
			for (const key of keys) {
				if (!(selectedData[key].id && selectedData[key].id === key)) continue;
				if (isDistrictMap || lastClickedPolygon === null) mData = selectedData[key];
				if (!isDistrictMap && lastClickedPolygon !== null)
					mData = selectedData[key].locations[lastClickedPolygon];
				topData = mData.data["stockOut"];
				leftData = mData.data[leftAttribute];
				let name = mData.name ? mData.name : mData.data.name ? mData.data.name : "No name";
				let words = name.split(" ");
				words = words.map(word => {
					return word[0].toUpperCase() + word.slice(1);
				});
				words = words.join(" ");
				if (!isDistrictMap) graphTitle = words;
				else graphTitle = "All Districts";
				if (leftAttribute === "population") {
					data = topData.map(n => {
						return leftData !== 0 ? Math.round((n / leftData + Number.EPSILON) * 100000) / 100 : 0;
					});
					graphTitle = "stock out / 1000 population : " + graphTitle;
				} else if (leftAttribute === "allProductsSold") {
					data = topData.map((n, i) => {
						return leftData[i] !== 0
							? Math.round((n / leftData[i] + Number.EPSILON) * 1000) //* 100
							: 0;
					});
					graphTitle = "stock out / 1000 products given out : " + graphTitle;
				} else if (leftAttribute === "footTraffic") {
					data = topData.map((n, i) => {
						return leftData[i] !== 0 ? Math.round((n / leftData[i] + Number.EPSILON) * 100000) : 0;
					});
					graphTitle = "stock out / 100,000 foot traffic : " + graphTitle;
				} else if (leftAttribute === "sec") {
					return this.secGraph();
				} else if (leftAttribute === "accessibility") {
					return this.accessibilityGraph();
				} else {
					data = topData;
					graphTitle = "stock out : " + graphTitle;
				}
				series.push({ name: words, data: data });
			}
			options.yaxis.title.text = graphTitle.split(" : ")[0];
			let graph = <Chart options={options} series={series} type={"bar"} height="150%" />;
			this.setState({ graph: null, graphIsRatio, graphTitle }, () => this.setState({ graph }));
		} catch (e) {
			console.error(e);
		}
	};

	populationGraph = () => {
		try {
			let { selectedData, isDistrictMap, leftAttribute, graphTitle, lastClickedPolygon } = this.state;
			let options = JSON.parse(JSON.stringify(this.state.options));
			let keys = Object.keys(selectedData);
			let series = [];
			let leftData = null;
			let mData = {};
			for (const key of keys) {
				if (!(selectedData[key].id && selectedData[key].id === key)) continue;
				if (isDistrictMap || lastClickedPolygon === null) mData = selectedData[key];
				if (!isDistrictMap && lastClickedPolygon !== null)
					mData = selectedData[key].locations[lastClickedPolygon];
				leftData = mData.data[leftAttribute];
				let name = mData.name ? mData.name : mData.data.name ? mData.data.name : "No name";
				let words = name.split(" ");
				words = words.map(word => {
					return word[0].toUpperCase() + word.slice(1);
				});
				words = words.join(" ");
				if (!isDistrictMap) graphTitle = words;
				else graphTitle = "All Districts";
				graphTitle = "population : " + graphTitle;
				series.push({ name: words, data: leftData });
			}
			options.yaxis.title.text = graphTitle.split(" : ")[0];
			options.xaxis.categories = series.map(s => {
				return s.name;
			});
			let popData = series.map(s => {
				return s.data;
			});
			series = [
				{
					name: "population",
					data: popData,
					type: "column"
				},
				{
					name: "population",
					data: popData,
					type: "line"
				}
			];
			options.legend.show = false;
			let graph = <Chart options={options} series={series} type={"line"} height="150%" />;
			this.setState({ graph: null, graphTitle }, () => this.setState({ graph }));
		} catch (e) {
			console.error(e);
		}
	};

	allProductsSoldGraph = () => {
		try {
			let { selectedData, isDistrictMap, leftAttribute, graphTitle, lastClickedPolygon } = this.state;
			let options = JSON.parse(JSON.stringify(this.state.options));
			let keys = Object.keys(selectedData);
			let series = [];
			let leftData = null;
			let mData = {};
			for (const key of keys) {
				if (!(selectedData[key].id && selectedData[key].id === key)) continue;
				if (isDistrictMap || lastClickedPolygon === null) mData = selectedData[key];
				if (!isDistrictMap && lastClickedPolygon !== null)
					mData = selectedData[key].locations[lastClickedPolygon];
				leftData = mData.data[leftAttribute];
				let name = mData.name ? mData.name : mData.data.name ? mData.data.name : "No name";
				let words = name.split(" ");
				words = words.map(word => {
					return word[0].toUpperCase() + word.slice(1);
				});
				words = words.join(" ");
				if (!isDistrictMap) graphTitle = words;
				else graphTitle = "All Districts";
				graphTitle = "all products sold : " + graphTitle;
				series.push({ name: words, data: leftData });
			}
			options.yaxis.title.text = graphTitle.split(" : ")[0];
			let graph = <Chart options={options} series={series} type={"bar"} height="150%" />;
			this.setState({ graph: null, graphTitle }, () => this.setState({ graph }));
		} catch (e) {
			console.error(e);
		}
	};

	footTrafficGraph = () => {
		try {
			let { selectedData, isDistrictMap, leftAttribute, graphTitle, lastClickedPolygon } = this.state;
			let options = JSON.parse(JSON.stringify(this.state.options));
			let keys = Object.keys(selectedData);
			let series = [];
			let leftData = null;
			let mData = {};
			for (const key of keys) {
				if (!(selectedData[key].id && selectedData[key].id === key)) continue;
				if (isDistrictMap || lastClickedPolygon === null) mData = selectedData[key];
				if (!isDistrictMap && lastClickedPolygon !== null)
					mData = selectedData[key].locations[lastClickedPolygon];
				leftData = mData.data[leftAttribute];
				let name = mData.name ? mData.name : mData.data.name ? mData.data.name : "No name";
				let words = name.split(" ");
				words = words.map(word => {
					return word[0].toUpperCase() + word.slice(1);
				});
				words = words.join(" ");
				if (!isDistrictMap) graphTitle = words;
				else graphTitle = "All Districts";
				graphTitle = "foot traffic : " + graphTitle;
				series.push({ name: words, data: leftData });
			}
			options.yaxis.title.text = graphTitle.split(" : ")[0];
			let graph = <Chart options={options} series={series} type={"bar"} height="150%" />;
			this.setState({ graph: null, graphTitle }, () => this.setState({ graph }));
		} catch (e) {
			console.error(e);
		}
	};

	secGraph = () => {
		try {
			let { selectedData, isDistrictMap, leftAttribute, graphTitle, lastClickedPolygon } = this.state;
			let options = JSON.parse(JSON.stringify(this.state.options));
			options.labels = [];
			options.legend.formatter = function(seriesName, opts) {
				return ["\n\r", seriesName, ":", "\n\r", opts.w.globals.series[opts.seriesIndex]];
			};
			let series = [];
			let counts = {
				A: 0,
				B: 0,
				C: 0
			};
			let keys = Object.keys(selectedData);
			let mData = {};
			for (const key of keys) {
				if (!(selectedData[key].id && selectedData[key].id === key)) continue;
				// if (isDistrictMap || lastClickedPolygon === null) mData = selectedData[key];
				// if (!isDistrictMap && lastClickedPolygon !== null)
				// 	mData = selectedData[key].locations[lastClickedPolygon];
				mData = selectedData[key];
				let name = mData.name ? mData.name : mData.data.name ? mData.data.name : "No name";
				let words = name.split(" ");
				words = words.map(word => {
					return word[0].toUpperCase() + word.slice(1);
				});
				words = words.join(" ");
				if (!isDistrictMap) graphTitle = words;
				else graphTitle = "All Districts";
				let lKeys = Object.keys(selectedData[key].locations);
				// if (isDistrictMap || lastClickedPolygon === null) {
				for (const lKey of lKeys) {
					counts[mData.locations[lKey].data.sec] += 1;
				}
				// } else counts[mData.data.sec] += 1;
			}
			Object.keys(counts).map(n => {
				series.push(counts[n]);
				options.labels.push(n);
			});
			let graph = <Chart options={options} series={series} type={"pie"} height="150%" />;
			this.setState({ graph: null, graphTitle }, () => this.setState({ graph }));
		} catch (e) {
			console.error(e);
		}
	};

	accessibilityGraph = () => {
		try {
			let { selectedData, isDistrictMap, graphTitle, lastClickedPolygon } = this.state;
			let options = JSON.parse(JSON.stringify(this.state.options));
			options.labels = [];
			options.legend.formatter = function(seriesName, opts) {
				return ["\n\r", seriesName, ":", "\n\r", opts.w.globals.series[opts.seriesIndex]];
			};
			let series = [];
			let counts = {
				"> 2 km": 0,
				"1 - 2 km": 0,
				"< 1 km": 0
			};
			let totalCount = 0;
			let keys = Object.keys(selectedData);
			let mData = {};
			for (const key of keys) {
				if (!(selectedData[key].id && selectedData[key].id === key)) continue;
				if (isDistrictMap || lastClickedPolygon === null) mData = selectedData[key];
				if (!isDistrictMap && lastClickedPolygon !== null)
					mData = selectedData[key].locations[lastClickedPolygon];
				let name = mData.name ? mData.name : mData.data.name ? mData.data.name : "No name";
				let words = name.split(" ");
				words = words.map(word => {
					return word[0].toUpperCase() + word.slice(1);
				});
				words = words.join(" ");
				if (!isDistrictMap) graphTitle = words;
				else graphTitle = "All Districts";
				if (isDistrictMap || lastClickedPolygon === null) {
					let lKeys = Object.keys(mData.locations);
					for (const lKey of lKeys) {
						counts["< 1 km"] += mData.locations[lKey].data.accessibility[0];
						counts["1 - 2 km"] += mData.locations[lKey].data.accessibility[1];
						counts["> 2 km"] += mData.locations[lKey].data.accessibility[2];
						totalCount += mData.locations[lKey].data.accessibility[0];
						totalCount += mData.locations[lKey].data.accessibility[1];
						totalCount += mData.locations[lKey].data.accessibility[2];
					}
				} else {
					counts["< 1 km"] += mData.data.accessibility[0];
					counts["1 - 2 km"] += mData.data.accessibility[1];
					counts["> 2 km"] += mData.data.accessibility[2];
					totalCount += mData.data.accessibility[0];
					totalCount += mData.data.accessibility[1];
					totalCount += mData.data.accessibility[2];
				}
			}
			Object.keys(counts).map(n => {
				series.push(Math.round((counts[n] / totalCount + Number.EPSILON) * 100));
				options.labels.push(n);
			});
			options.legend.inverseOrder = true;
			let graph = <Chart options={options} series={series} type={"radialBar"} height="150%" />;
			this.setState({ graph: null, graphTitle }, () => this.setState({ graph }));
		} catch (e) {
			console.error(e);
		}
	};

	render() {
		return (
			<div className="container text-center">
				<div style={{ height: "60%" }}>
					<CardHeader>{this.state.graphTitle}</CardHeader>
					<div
						style={{
							height: "100%",
							marginTop: this.state.marginTop
						}}
					>
						{this.state.graph}
					</div>
				</div>
			</div>
		);
	}
}

export default GraphComponent;
