import React, { PureComponent, Fragment } from "react";
import update from "immutability-helper";
import "../../assets/css/google-map.css";
import { Col } from "reactstrap";
import gatesMapLegend from "../../views/gates/utils/mapLegends";
const {
	compose,
	withProps,
	withHandlers
	// withStateHandlers
} = require("recompose");
const {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	// KmlLayer,
	Marker,
	Polygon,
	InfoWindow
} = require("react-google-maps");
// const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

// const CLUSTER_GRID_SIZE = 60;
const MAP_HEIGHT = "595px";

const { MyStyle } = require("../../utils/constants/google-maps-styles.js");
const blackIcon = require("../../assets/images/black-dot-8.png");
const redIcon = require("../../assets/images/red-dot-16.png");
const imageList = [
	require("../../assets/images/health1.jpeg"),
	require("../../assets/images/health2.jpeg"),
	require("../../assets/images/health3.jpeg"),
	require("../../assets/images/health4.jpeg"),
	require("../../assets/images/health5.jpeg"),
	require("../../assets/images/health6.jpeg"),
	require("../../assets/images/health7.jpeg"),
	require("../../assets/images/health8.jpeg"),
	require("../../assets/images/health9.jpeg"),
	require("../../assets/images/health10.jpeg"),
	require("../../assets/images/health11.jpeg"),
	require("../../assets/images/health12.jpeg"),
	require("../../assets/images/health13.jpeg"),
	require("../../assets/images/health14.jpeg"),
	require("../../assets/images/health15.jpeg"),
	require("../../assets/images/health16.jpeg"),
	require("../../assets/images/health17.jpeg"),
	require("../../assets/images/health18.jpeg"),
	require("../../assets/images/health19.jpeg")
];
// const colorList = [["#f1c27d", "#b3cde0", "#83d0c9", "#e3c9c9", "#ff8b94", "#8c9da9", "#8874a3"]];

export class GoogleMaps extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			polygons: {},
			voronois: {},
			markers: {},
			polygonList: [],
			voronoiList: [],
			markerList: [],
			polyChanged: true,
			voronoiChanged: true,
			markersChanged: true,
			zoom: 7,
			center: { lat: 26.20011399986849, lng: 68.57073300016597 }
		};
		this.actionToClass = {
			over: "hover",
			out: "base",
			down: "click",
			up: "hover"
		};
		this.polygonOpacityLevels = {
			base: 0.25,
			hover: 0.5,
			click: 0.75
		};
		this.voronoiOpacityLevels = {
			base: 0.5,
			hover: 0.75,
			click: 1.0
		};
		this.styleIndex = 2;
		this.map = {};
	}

	componentWillMount() {
		console.log("MOUNT");
		this.setData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.voronoiChanged !== this.props.voronoiChanged) this.setData();
	}

	setData = () => {
		let zoom = this.state.zoom;
		let center = { ...this.state.center };
		// let polygons = {};
		let voronois = {};
		let markers = {};
		let polygonList = [];
		let voronoiList = [];
		let markerList = [];
		if (this.props.zoom) zoom = this.props.zoom;
		if (this.props.center) center = { ...this.props.center };
		if (this.props.selectedData) {
			let selectedData = this.props.selectedData;
			let keys = Object.keys(selectedData);
			for (const key of keys) {
				if (selectedData[key].id && selectedData[key].id === key) {
					// let polygonObject = {};
					// polygonObject.id = key;
					// polygonObject.coordinates = selectedData[key].boundary.polygon;
					// polygonObject.style = selectedData[key].boundary.style;
					// polygonObject.gradient = selectedData[key].gradient;
					// polygonObject.marker = selectedData[key].center.marker;
					// polygonObject.data = selectedData[key].data;
					let lKeys = Object.keys(selectedData[key].locations);
					// polygonObject.locations = [...lKeys];
					// polygons[key] = polygonObject;
					markers[key] = { ...selectedData[key].center.marker };
					for (const lKey of lKeys) {
						let location = selectedData[key].locations[lKey];
						let voronoiObject = {};
						voronoiObject.id = location.id;
						voronoiObject.coordinates = location.boundary.polygon;
						voronoiObject.style = location.boundary.style;
						// voronoiObject.gradient = selectedData[key].gradient;
						voronoiObject.marker = location.center.marker;
						voronoiObject.data = location.data;
						markers[lKey] = { ...location.center.marker };
						voronois[lKey] = voronoiObject;
					}
				}
			}
			// let pKeys = Object.keys(polygons);
			// for (const [i, key] of pKeys.entries()) {
			// 	polygonList.push(this.buildPolygonsFromData(polygons[key], key, i));
			// }
			let vKeys = Object.keys(voronois);
			for (const [i, key] of vKeys.entries()) {
				voronoiList.push(this.buildVoronoisFromData(voronois[key], key, i));
			}
			let mKeys = Object.keys(markers);
			for (const [i, key] of mKeys.entries()) {
				markerList.push(this.buildMarkersFromData(markers[key], key, i));
			}
		}
		// this.setState({ polygons, voronois, markers, zoom, center, polygonList, voronoiList, markerList });
		this.setState({ voronois, markers, zoom, center, polygonList, voronoiList, markerList });
	};

	buildPolygonsFromData = (poly, key, index) => {
		return (
			poly.coordinates &&
			poly.coordinates.length > 0 &&
			poly.coordinates.map((coord, j) => {
				if (coord.length > 0 && !coord[0].length) {
					return (
						<Polygon
							path={coord}
							key={`poly${j}-${key}`}
							editable={false}
							options={{
								strokeColor: "#000000", //poly.style.color,
								strokeOpacity: 1,
								strokeWeight: 1,
								fillColor: poly.style.fillColor,
								fillOpacity: this.polygonOpacityLevels[poly.style.current]
							}}
							onClick={() => this.handlePolygonClick(key, index)}
							onMouseOver={() => this.handlePolygonMouseAction(key, index, "over")}
							onMouseOut={() => this.handlePolygonMouseAction(key, index, "out")}
							onMouseUp={() => this.handlePolygonMouseAction(key, index, "up")}
							onMouseDown={() => this.handlePolygonMouseAction(key, index, "down")}
						/>
					);
				} else {
					return (
						coord &&
						coord.map((c, k) => {
							return (
								<Polygon
									path={c}
									key={`poly${j + k + 1}-${key}`}
									editable={false}
									options={{
										strokeColor: "#000000", //poly.style.color,
										strokeOpacity: 1,
										strokeWeight: 1,
										fillColor: poly.style.fillColor,
										fillOpacity: this.polygonOpacityLevels[poly.style.current]
										// "fillOpacity": 0
									}}
									onClick={() => this.handlePolygonClick(key, index)}
									onMouseOver={() => this.handlePolygonMouseAction(key, index, "over")}
									onMouseOut={() => this.handlePolygonMouseAction(key, index, "out")}
									onMouseUp={() => this.handlePolygonMouseAction(key, index, "up")}
									onMouseDown={() => this.handlePolygonMouseAction(key, index, "down")}
								/>
							);
						})
					);
				}
			})
		);
	};

	buildVoronoisFromData = (voronoi, key, index) => {
		try {
			return (
				<Polygon
					path={voronoi.coordinates}
					key={`voro${index}-${key}`}
					editable={false}
					options={{
						strokeColor: "#000000",
						strokeOpacity: 0.8,
						strokeWeight: 1,
						fillColor: voronoi.style.fillColor,
						fillOpacity: this.voronoiOpacityLevels[voronoi.style.current]
						// "fillOpacity": (voronoi.style.current === "base") ? 0.35 : (voronoi.style.current === "hover") ? 0.75 : 1.00
					}}
					onClick={() => this.handleVoronoiClick(key, index)}
					onMouseOver={() => this.handleVoronoiMouseAction(key, index, "over")}
					onMouseOut={() => this.handleVoronoiMouseAction(key, index, "out")}
					onMouseUp={() => this.handleVoronoiMouseAction(key, index, "up")}
					onMouseDown={() => this.handleVoronoiMouseAction(key, index, "down")}
				/>
			);
		} catch (e) {
			console.error(e);
		}
	};

	buildMarkersFromData = (marker, key, index) => {
		return (
			<Marker
				position={{ lat: marker.position.lat, lng: marker.position.lng }}
				key={`marker${index}-${key}`}
				icon={!marker.isPoi ? redIcon : blackIcon}
				label={
					!marker.isPoi
						? {
								color: "#005500",
								text: marker.label,
								fontWeight: "bold"
						  }
						: null
				}
				onClick={marker.isPoi && !marker.isOpen ? () => this.handleMarkerClick(key, index) : null}
			>
				{marker.isPoi && marker.isOpen && marker.label && marker.label.length && (
					<InfoWindow
						onCloseClick={marker.isPoi && marker.isOpen ? () => this.handleMarkerClick(key, index) : null}
					>
						<div>
							<p>{marker.label}</p>
							<img
								src={`${imageList[Math.floor(Math.random() * (imageList.length - 1 - 0)) + 0]}`}
								style={{ width: "240px", height: "160px" }}
								alt=""
							/>
						</div>
					</InfoWindow>
				)}
			</Marker>
		);
	};

	handlePolygonClick = (key, index) => {
		try {
			let target = { ...this.state.polygons[key] };
			if (this.props.polygonClick) this.props.polygonClick(target);
			return target;
		} catch (e) {
			console.error(e);
		}
	};

	handlePolygonMouseAction = (key, index, action) => {
		try {
			let target = { ...this.state.polygons[key] };
			if (action === "up") {
				target.style.previous = this.actionToClass[action];
				target.style.current = this.actionToClass[action];
			} else {
				target.style.previous = target.style.current;
				target.style.current = this.actionToClass[action];
			}
			let polygon = this.buildPolygonsFromData(target, key, index);
			let polygons = update(this.state.polygons, { [key]: { style: { $set: { ...target.style } } } });
			let polygonList = update(this.state.polygonList, { [index]: { $set: polygon } });
			this.setState({ polygons, polygonList });
		} catch (e) {
			console.error(e);
		}
	};

	handleVoronoiClick = (key, index) => {
		try {
			let target = { ...this.state.voronois[key] };
			this.props.VoronoiClicked(target);
			if (this.props.voronoiClick) this.props.voronoiClick(target);
			return target;
		} catch (e) {
			console.error(e);
		}
	};

	handleVoronoiMouseAction = (key, index, action) => {
		try {
			let target = { ...this.state.voronois[key] };
			if (action === "up") {
				target.style.previous = this.actionToClass[action];
				target.style.current = this.actionToClass[action];
			} else {
				target.style.previous = target.style.current;
				target.style.current = this.actionToClass[action];
			}
			let voronoi = this.buildVoronoisFromData(target, key, index);
			let voronois = update(this.state.voronois, { [key]: { style: { $set: { ...target.style } } } });
			let voronoiList = update(this.state.voronoiList, { [index]: { $set: voronoi } });
			this.setState({ voronois, voronoiList });
		} catch (e) {
			console.error(e);
		}
	};

	handleMarkerClick = (key, index) => {
		try {
			let target = { ...this.state.markers[key] };
			target.isOpen = !target.isOpen;
			let marker = this.buildMarkersFromData(target, key, index);
			let markers = update(this.state.markers, { [key]: { isOpen: { $set: !this.state.markers[key].isOpen } } });
			let markerList = update(this.state.markerList, { [index]: { $set: marker } });
			this.setState({ markers, markerList });
			if (this.props.markerClick) this.props.markerClick(target);
		} catch (e) {
			console.error(e);
		}
	};

	handleMarkerMouseAction = (key, index, action) => {
		try {
			let target = { ...this.state.markers[key] };
			return target;
		} catch (e) {
			console.error(e);
		}
	};

	handleDistrictMapToggle = () => {
		try {
			this.setState({ polygons: {}, voronois: {}, markers: {}, selectedData: {} }, () => {
				this.props.toggleDistrictMap(null);
			});
		} catch (e) {
			console.error(e);
		}
	};

	getRef = map => {
		try {
			this.map = map;
			this.map.panTo({ ...this.state.center });
		} catch (e) {
			console.error(e);
		}
	};

	// createLegend = (dataMin, dataMax, index) => {
	// 	let legend = [];
	// 	let colorDelta = (dataMax - dataMin) / colorList[index].length;
	// 	for (let i = 0; i < colorList[index].length - 1; i++) {
	// 		legend.push({
	// 			population: `${dataMin + Math.floor(colorDelta * i)} - ${dataMin + Math.floor(colorDelta * (i + 1))}`,
	// 			color: colorList[index][i]
	// 		});
	// 	}
	// 	return legend;
	// };

	// updateVoronoiColors = (Map, Data, attribute, min, max, onClickHandler, selectedAccessLevel) => {
	// 	try {
	// 		let mKeys = Object.keys(Map);
	// 		let colorListIndex = 0;
	// 		let allColors = colorList[colorListIndex];
	// 		let voronois = [];
	// 		let inRangeCount = 0;
	// 		let offRangeCount = 0;
	// 		let dataMin = 0,
	// 			dataMax = 0;
	// 		let colorDelta = 0;
	// 		let voronoisColor = allColors[allColors.length - 1];

	// 		if (attribute === "market share by access") {
	// 			dataMin = Data.aggregate.ranges[attribute].min[selectedAccessLevel];
	// 			dataMax = Data.aggregate.ranges[attribute].max[selectedAccessLevel];
	// 		} else {
	// 			dataMin = Data.aggregate.ranges[attribute].min;
	// 			dataMax = Data.aggregate.ranges[attribute].max;
	// 		}
	// 		colorDelta = (dataMax - dataMin) / allColors.length;
	// 		let legend = this.createLegend(dataMin, dataMax, colorListIndex);
	// 		// for (let i = 0; i < allColors.length - 1; i++) {
	// 		// 	legend.push({
	// 		// 		population: `${dataMin + Math.floor(colorDelta * i)} - ${dataMin + Math.floor(colorDelta * (i + 1))}`,
	// 		// 		color: allColors[i]
	// 		// 	});
	// 		// }
	// 		for (const key of mKeys) {
	// 			const polygon = Map[key];
	// 			let value = 0;
	// 			if (attribute === "market share by access") {
	// 				value = Data.locations[key].data.average[attribute][selectedAccessLevel];
	// 			} else {
	// 				value = Data.locations[key].data.average[attribute];
	// 			}
	// 			// if (Data.locations[key].data.average[attribute] < min || Data.locations[key].data.average[attribute] > max) {
	// 			for (let i = 0; i < allColors.length - 1; i++) {
	// 				if (value >= dataMin + colorDelta * i && value < dataMin + colorDelta * (i + 1)) {
	// 					voronoisColor = allColors[i];
	// 					break;
	// 				}
	// 			}
	// 			if (value < min || value > max) {
	// 				offRangeCount++;
	// 				voronois.push(
	// 					<Polygon
	// 						key={`voronoi_${key}`}
	// 						paths={polygon.boundary}
	// 						// strokeColor={voronoisColor}
	// 						strokeColor={"#000000"}
	// 						strokeOpacity={0.7}
	// 						strokeWeight={1}
	// 						fillColor={voronoisColor}
	// 						fillOpacity={0.0}
	// 						onClick={() => onClickHandler(key)}
	// 					/>
	// 				);
	// 			} else {
	// 				inRangeCount++;
	// 				voronois.push(
	// 					<Polygon
	// 						key={`voronoi_${key}`}
	// 						paths={polygon.boundary}
	// 						// strokeColor={voronoisColor}
	// 						strokeColor={"#000000"}
	// 						strokeOpacity={0.7}
	// 						strokeWeight={1}
	// 						fillColor={voronoisColor}
	// 						fillOpacity={0.5}
	// 						onClick={() => onClickHandler(key)}
	// 					/>
	// 				);
	// 			}
	// 		}
	// 		return { voronois, inRangeCount, offRangeCount, legend };
	// 	} catch (e) {
	// 		console.error(e);
	// 		throw e;
	// 	}
	// };

	renderMapLegends = () => {
		let { legend } = this.props;
		if (legend && legend.length > 0) {
			return (
				<Col className="">
					<div className="mapLegendsWrapper">
						<button className="backtoMapBtn backButton btn btn-sm" onClick={this.handleDistrictMapToggle}>
							<i className="fa fa-chevron-left"></i>
						</button>
						{legend.map(item => (
							<span className={"mapLegends"} style={{ color: item.color }}>
								<span className="mapLegendsBox" style={{ backgroundColor: item.color }}></span>
								{item.text}
							</span>
						))}
					</div>
				</Col>
			);
		}
	};

	render() {
		return (
			<Fragment>
				<div className="container">
					{/* <button className="button backButton btn btn-sm" onClick={this.handleDistrictMapToggle}>
						<i className="fa fa-chevron-left"></i>
					</button> */}
					{this.renderMapLegends()}
					{/* <Col
						style={{
							justifyContent: "center",
							alignItems: "center",
							position: "absolute",
							top: 0,
							left: 0,
							zIndex: 100,
							backgroundColor: "rgba(255, 255, 255, 0.7)",
							height: 40,
							paddingTop: 10
						}}
					>
						
						{gatesMapLegend.population.map((item, index) => (
							<span className={"mapLegends zoom ml-1 mb-1"} style={{ color: item.color }}>
								<span className="mapLegendsBox" style={{ backgroundColor: item.color }}></span>
								{item.population}
							</span>
						))}
					</Col> */}
					<Map
						getRef={this.getRef}
						styleIndex={this.styleIndex}
						center={this.state.center}
						zoom={this.state.zoom}
						// polygonList={this.state.polygonList}
						voronoiList={this.state.voronoiList}
						markerList={this.state.markerList}
					/>
				</div>
			</Fragment>
		);
	}
}

const withPropsOutput = withProps({
	googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=geometry,drawing,places`,
	loadingElement: <div style={{ height: `100%` }} />,
	containerElement: <div style={{ height: MAP_HEIGHT, width: "100%" }} />,
	mapElement: <div style={{ height: `100%` }} />
});

const withHandlersOutput = withHandlers({
	onMarkerClustererClick: () => markerClusterer => {
		markerClusterer.getMarkers();
	}
});

const MapComponent = props => {
	let { styleIndex, center, zoom, polygonList, voronoiList, markerList, getRef } = props;
	return (
		<GoogleMap
			defaultZoom={zoom}
			defaultCenter={center}
			defaultOptions={{
				styles: MyStyle[styleIndex],
				mapTypeControl: false,
				fullscreenControl: false,
				streetViewControl: false
			}}
			ref={getRef}
		>
			{polygonList && polygonList.length > 0 && [...polygonList]}
			{voronoiList && voronoiList.length > 0 && [...voronoiList]}
			{markerList && markerList.length > 0 && [...markerList]}
		</GoogleMap>
	);
};

const Map = compose(withPropsOutput, withScriptjs, withGoogleMap, withHandlersOutput)(props => MapComponent(props));
