import React, { Component, Fragment } from "react";
import { CustomInput } from "reactstrap";
import Axios from "axios";
import GoogleMap from "../common/google_map";
import TabularData from "../data/tabular_data.json";
import MapData from "../data/hd_data.json";
import SecData from "../data/secs.json";
import {
	createMarkersFromType,
	createMarkersFromSegment,
	createVoronois,
	createBoxes,
	createBoxMarkers,
	createPopulationVoronois,
	createSecPolygons,
	createVoronoiInfoWindow,
	updateVoronoiColors,
	createSelectedBox,
	createBoxesInBoundary
} from "../utils/map_data";
import { RangeSlider } from "../../../components/Sliders/RangeSlider";
import JazzData from "../data/jazz-data.json";
import { Col } from "reactstrap";
import mapLegend from "../utils/mapLegendsData";
// import shop from "../../../assets/images/conducted_at_icon.png";
import shop from "../../../assets/images/black-dot-8.png";
import { Marker, Polygon, InfoWindow } from "google-maps-react";

const ZOOM_LIMIT = 16;
const S3_URL = "https://jazz-analysis.s3-ap-southeast-1.amazonaws.com/raw-data";
class Map extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userTypeMarkers: [],
			userSegmentMarkers: [],
			voronois: [],
			boxes: [],
			boxMarkers: [],
			zoom: 11,
			infoWindows: [],
			slider: {
				active: false,
				min: 20,
				max: 30,
				defaultValue: [20, 30],
				count: 10,
				step: 0.1,
				value: []
			},
			selectedBox: [],
			franchises: [],
			selectedAccessLevel: 0,
			legend: {},
			loading: false,
			layer: [],
			map: null
		};
		this.layers = {
			voronois: this.createJazzVoronois(),
			population: createPopulationVoronois(MapData, this.handlePolygonHover),
			seclevel: createSecPolygons(SecData)
		};
	}

	async componentWillMount() {
		let keys = Object.keys(MapData);
		let franchises = [];

		keys.map((item, i) => {
			let marker = (
				<Marker
					key={`fm_${i}`}
					position={{ lat: MapData[item].poi.lat, lng: MapData[item].poi.lng }}
					icon={shop}
				/>
			);
			franchises.push(marker);
		});
		this.setState({ franchises });
	}

	componentDidMount() {
		this.setState({ voronois: this.layers.voronois });
	}

	async componentDidUpdate(prevProps, prevState, snapshot) {
		let updates = {};
		if (prevProps.selectedTypes.length !== this.props.selectedTypes.length) {
			const userTypeMarkers = createMarkersFromType(TabularData, this.props.selectedTypes);
			updates.userTypeMarkers = [...userTypeMarkers];
		}
		if (prevProps.selectedSegments.length !== this.props.selectedSegments.length) {
			const userSegmentMarkers = createMarkersFromSegment(TabularData, this.props.selectedSegments);
			updates.userSegmentMarkers = [...userSegmentMarkers];
		}

		if (this.props.selectedBox && prevProps.selectedBox !== this.props.selectedBox)
			updates.selectedBox = [
				createSelectedBox(MapData[this.props.selectedVoronoi].boxes, this.props.selectedBox)
			];
		if (prevProps.selectedVoronoi !== this.props.selectedVoronoi) {
			if (this.props.selectedVoronoi !== null) {
				if (!MapData[this.props.selectedVoronoi].boxes) {
					this.setState({ loading: true });
					try {
						const { data } = await Axios.get(`${S3_URL}/${this.props.selectedVoronoi}.json`);
						MapData[this.props.selectedVoronoi].boxes = [...data];
					} catch (error) {
						console.log(error);
					}
				}
				// if (this.props.selectedMenu !== "population" && this.props.selectedMenu !== "seclevel") {
				// 	updates.boxes = await createBoxes(
				// 		MapData[this.props.selectedVoronoi].boxes,
				// 		this.props.handleSelectBox
				// 	);
				// }
			}
			this.onBoundsChanged();
			updates.voronois = this.createJazzVoronois();
			updates.infoWindows = [];
			updates.selectedBox = [];
		}
		if (prevProps.selectedMenu !== this.props.selectedMenu) {
			if (this.props.selectedMenu) {
				updates.layer = [...this.layers[this.props.selectedMenu]];
				this._resetSelect();
			} else {
				updates.layer = [];
			}
		}

		if (prevProps.selectedTab !== this.props.selectedTab) {
			if (this.props.selectedTab !== null) {
				this.setState({ slider: { ...this.state.slider, active: false } }, () => {
					let range = {};
					if (this.props.selectedTab !== 2)
						range =
							JazzData["LAHORE"].data.ranges[
								this.props.selectedTab === 0 ? "market share" : "revenue potential"
							];
					else {
						range = {
							min:
								JazzData["LAHORE"].data.ranges["market share by access"].min[
									this.state.selectedAccessLevel
								],
							max:
								JazzData["LAHORE"].data.ranges["market share by access"].max[
									this.state.selectedAccessLevel
								]
						};
					}
					this.setState(
						{
							slider: {
								...this.state.slider,
								// min: Math.round(range.min - 5),
								// max: Math.round(range.max + 5),
								// defaultValue: [Math.round(range.min - 5), Math.round(range.max + 5)],
								min: Math.round(range.min),
								max: Math.round(range.max),
								defaultValue: [Math.round(range.min), Math.round(range.max)],
								active: true
							}
						},
						() => this.handleOnSlide([this.state.slider.min, this.state.slider.max])
					);
				});
			} else {
				this.setState({ slider: { ...this.state.slider, active: false }, layer: [] });
			}
		}
		if (Object.keys(updates).length)
			this.setState({ ...updates }, () =>
				setTimeout(() => {
					this.setState({ loading: false });
				}, 1000)
			);
	}

	createJazzVoronois = _ => {
		const { handleSelectVoronoi, selectedVoronoi } = this.props;
		return createVoronois(MapData, handleSelectVoronoi, selectedVoronoi);
	};

	onBoundsChanged = (_, map = this.state.map) => {
		const newZoom = map.getZoom();
		const NE = map.getBounds().getNorthEast();
		const SW = map.getBounds().getSouthWest();
		const { zoom } = this.state;
		const { selectedVoronoi } = this.props;
		const updates = {};
		// Leaving the zoom range of showing numbers
		if (newZoom < ZOOM_LIMIT && zoom >= ZOOM_LIMIT) {
			updates.boxMarkers = [];
			updates.boxes = [];
			updates.selectedBox = [];
		} else if (newZoom >= ZOOM_LIMIT && selectedVoronoi) {
			const boxMarkers = createBoxMarkers(MapData[selectedVoronoi].boxes, NE.lat(), SW.lat(), SW.lng(), NE.lng());
			const boxes = createBoxesInBoundary(
				MapData[selectedVoronoi].boxes,
				this.props.handleSelectBox,
				NE.lat(),
				SW.lat(),
				SW.lng(),
				NE.lng()
			);
			updates.boxMarkers = boxMarkers;
			updates.boxes = boxes;
			if (this.props.selectedBox)
				updates.selectedBox = [
					createSelectedBox(MapData[this.props.selectedVoronoi].boxes, this.props.selectedBox)
				];
		}
		updates.zoom = newZoom;
		updates.map = map;
		this.setState({ ...updates });
	};

	handlePolygonHover = polygonId => {
		if (this.props.selectedMenu === "population") {
			const infoWindow = createVoronoiInfoWindow(MapData[polygonId]);
			this.setState({ infoWindows: [infoWindow] });
		}
	};

	handleOnSlide = value => {
		try {
			let min = value[0],
				max = value[1];
			let attribute = "";
			if (this.props.selectedTab === 0) attribute = "market share";
			else if (this.props.selectedTab === 1) attribute = "revenue potential";
			else if (this.props.selectedTab === 2) attribute = "market share by access";
			if (attribute !== "") {
				let { voronois, inRangeCount, offRangeCount, legend } = updateVoronoiColors(
					MapData,
					{ locations: JazzData["LAHORE"].locations, aggregate: JazzData["LAHORE"].data },
					attribute,
					min,
					max,
					this.props.handleSelectVoronoi,
					this.state.selectedAccessLevel
				);
				this.setState({ layer: [] }, () =>
					this.setState({ slider: { ...this.state.slider, value }, layer: voronois, legend })
				);
			}
		} catch (e) {
			console.error(e);
		}
	};

	_resetSelect = () => {
		this.setState({ selectedBox: [], boxes: [] }, () => this.props.handleSelectVoronoi(null));
	};

	render() {
		let {
			userTypeMarkers,
			userSegmentMarkers,
			voronois,
			boxes,
			boxMarkers,
			infoWindows,
			slider,
			selectedBox,
			franchises,
			layer,
			legend // only for market share, revenue potential and access level
		} = this.state;
		let rangeSlider = (
			<div className="rangeSliderInnerWrap">
				<h6 className="sliderTitle">
					{this.props.selectedTab === 0
						? "Market Share"
						: this.props.selectedTab === 1
						? "Revenue Potential"
						: "Market Share by Access Level"}
				</h6>
				<RangeSlider
					min={slider.min}
					max={slider.max}
					defaultValue={slider.defaultValue}
					count={slider.count}
					onSlide={this.handleOnSlide}
					selectedTab={this.props.selectedTab}
					step={slider.step}
				/>
				{this.props.selectedTab === 2 && (
					<div>
						<div className="distanceChecking">
							<CustomInput
								type="radio"
								id="distanceSelector"
								name="distance"
								label="Less than 1 km"
								checked={this.state.selectedAccessLevel === 0}
								value={0}
								onChange={changeEvent =>
									this.setState({ selectedAccessLevel: parseInt(changeEvent.target.value) }, () =>
										this.handleOnSlide(this.state.slider.min, this.state.slider.max)
									)
								}
							/>
							<CustomInput
								type="radio"
								id="distanceSelector2"
								name="distance"
								label="1 km - 2 km"
								checked={this.state.selectedAccessLevel === 1}
								value={1}
								onChange={changeEvent =>
									this.setState({ selectedAccessLevel: parseInt(changeEvent.target.value) }, () =>
										this.handleOnSlide(this.state.slider.min, this.state.slider.max)
									)
								}
							/>
							<CustomInput
								type="radio"
								id="distanceSelector3"
								name="distance"
								label="Greater than 2 km"
								checked={this.state.selectedAccessLevel === 2}
								value={2}
								onChange={changeEvent =>
									this.setState({ selectedAccessLevel: parseInt(changeEvent.target.value) }, () =>
										this.handleOnSlide(this.state.slider.min, this.state.slider.max)
									)
								}
							/>
						</div>
					</div>
				)}
			</div>
		);
		return (
			<GoogleMap
				markers={[...userTypeMarkers, ...userSegmentMarkers, ...boxMarkers]}
				polygons={[...layer, ...voronois]}
				boxes={[...selectedBox, ...boxes]}
				infoWindows={[...infoWindows]}
				onBoundsChanged={this.onBoundsChanged}
				selectedTab={this.props.selectedTab}
				rangeSlider={slider.active ? rangeSlider : null}
				selectedMenu={this.props.selectedMenu}
				franchises={franchises}
				legend={legend}
				loading={this.state.loading}
				resetSelect={() => this._resetSelect()}
			/>
		);
	}
}

export default Map;
