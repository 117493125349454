import React, { Component } from "react";
import "./App.css";
import "./architectuiassets/base.scss";
import { Switch, BrowserRouter, Route } from "react-router-dom";
// import MainLayout from "./layout/MainLayout";
import GatesLayout from "./views/gates/index";
import { Provider } from "react-redux";
import configureStore from "./redux/config/configureStore";
import history from "./history";
import { JazzDashboard } from "./views/jazz";

const store = configureStore();
class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<BrowserRouter history={history}>
					{/* <Suspense> */}
					<Switch>
						{/* <Route exact path="/jazz" component={JazzDashboard} />
						<Route exact path="/:id" component={GatesLayout} />
						<Route exact path="/" component={GatesLayout} /> */}
						<Route exact path="/" component={JazzDashboard} />
						{/* <Route exact path="/jazz" component={JazzDashboard} /> */}
						{/* <Route exact path="/gates/:id" component={GatesLayout} /> */}
						{/* <Route exact path="/gates" component={GatesLayout} /> */}
						{/* <Route to="not-found" component="Not found" /> */}
					</Switch>
					{/* </Suspense> */}
				</BrowserRouter>
			</Provider>
		);
	}
}

export default App;
