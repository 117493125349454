import React, { Component, Fragment } from "react";
import Header from "../../layout/AppHeader/index";
import AppSideBar from "../../layout/AppSidebar/index";
import ResizeDetector from "react-resize-detector";
import { connect } from "react-redux";
import cx from "classnames";
import { withRouter } from "react-router-dom";
import Monitoring from "./Monitoring";
import Analytics from "./Analytics";
import ScrollUpButton from "react-scroll-up-button";
const gatesMenu = {
	top: [
		{ label: "Stock", icon: "fas fa-cubes", selected: true },
		{ label: "Units Given Out", icon: "fas fa-people-carry", selected: false },
		{ label: "Stock Out", icon: "fas fa-box-open", selected: false }
	],
	left: [
		{
			icon: "fas fa-users",
			label: "Population"
		},
		{
			icon: "fa fa-barcode",
			label: "All products"
		},
		{
			icon: "fas fa-shoe-prints",
			label: "Foot Traffic"
		},
		{
			icon: "fas fa-chart-bar",
			label: "SEC Level"
		},
		{
			icon: "fas fa-map-marked",
			label: "Accessibility"
		}
	]
};

class GatesLayout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			allData: {},
			isDistrictMap: true,
			selectedData: {},
			googleMapCenter: {},
			googleMapZoom: 7,
			top: {
				selectedProduct: { value: "Sayana", label: "Sayana Press" },
				topTab: 0,
				start_date: null,
				end_date: null,
				isToggleOn: true
			},
			leftTab: "",
			someId: this.props.match.params.id,
			disabled: true,
			selectedVoronoi: ""
		};
	}

	_onDatesChange = async ({ startDate, endDate }) => {
		await this.setState({
			top: { ...this.state.top, start_date: startDate, end_date: endDate }
		});
	};

	_toggleInnerViews = () => {
		if (this.state.top.isToggleOn) {
			let topTab = this.state.top.topTab;
			return (
				<Analytics
					topTab={topTab}
					topTabName={
						topTab !== null && topTab !== undefined ? gatesMenu.top[topTab].label.toLowerCase() : null
					}
					leftTab={this.state.leftTab ? this.state.leftTab.toLowerCase() : null}
				/>
			);
		} else {
			return <Monitoring />;
		}
	};

	_onProductChange = event => {
		this.setState({ top: { ...this.state.top, selectedProduct: event } });
	};

	_onChangeTopTabs = tabId => {
		if (tabId !== undefined && tabId !== null) {
			if (this.state.top.topTab === tabId) this.setState({ top: { ...this.state.top, topTab: null } });
			else this.setState({ top: { ...this.state.top, topTab: tabId } });
		}
	};

	_onChangeLeftMenu = itemId => {
		if (itemId) {
			if (this.state.leftTab === itemId.toLowerCase()) this.setState({ leftTab: null });
			else this.setState({ leftTab: itemId.toLowerCase() });
		}
	};

	handleMicroSurveyClick = () => {
		let { selectedVoronoi } = this.state;
		console.log(selectedVoronoi);
		if (selectedVoronoi !== null) {
			let markers = [selectedVoronoi.marker.position];
			// if (selectedSegments.length > 0 || selectedTypes.length > 0) {
			// 	for (const data of TabData) {
			// 		let match = false;
			// 		for (const segment of selectedSegments) {
			// 			if (
			// 				parseInt(data.polygon) === selectedPolygon &&
			// 				data.grade.toLowerCase() === segment.toLowerCase()
			// 			) {
			// 				match = true;
			// 				break;
			// 			}
			// 		}
			// 		if (!match) {
			// 			for (const type of selectedTypes) {
			// 				if (
			// 					parseInt(data.polygon) === selectedPolygon &&
			// 					data.type.toLowerCase() === type.toLowerCase()
			// 				) {
			// 					match = true;
			// 					break;
			// 				}
			// 			}
			// 		}
			// 		if (match) markers.push({ lat: data.lat, lng: data.lon });
			// 	}
			// }
			window.open(
				process.env.REACT_APP_SURVEY_AUTO_COMPOSER_FRONTEND + "?markers=" + encodeURI(JSON.stringify(markers)),
				"_blank"
			);
		}
	};

	VoronoiClicked = selectedVoronoi => {
		console.log("selectedVoronoi: ", selectedVoronoi);
		this.setState({ disabled: !this.state.disabled, selectedVoronoi: selectedVoronoi });
	};

	render() {
		let topTab = this.state.top.topTab;
		let {
			colorScheme,
			enableFixedHeader,
			enableFixedSidebar,
			enableFixedFooter,
			enableClosedSidebar,
			closedSmallerSidebar,
			enableMobileMenu,
			enablePageTabsAlt
		} = this.props;
		return (
			<ResizeDetector
				handleWidth
				render={({ width }) => (
					<Fragment>
						<div
							className={cx(
								"app-container app-theme-" + colorScheme,
								{ "fixed-header": enableFixedHeader },
								{ "fixed-sidebar": enableFixedSidebar || width < 1250 },
								{ "fixed-footer": enableFixedFooter },
								{ "closed-sidebar": enableClosedSidebar || width < 1250 },
								{ "closed-sidebar-mobile": closedSmallerSidebar || width < 1250 },
								{ "sidebar-mobile-open": enableMobileMenu },
								{ "body-tabs-shadow-btn": enablePageTabsAlt }
							)}
						>
							<Header
								menuItems={gatesMenu.top}
								top={this.state.top}
								toggleSwitch={() =>
									this.setState({
										top: { ...this.state.top, isToggleOn: !this.state.top.isToggleOn }
									})
								}
								changeDates={this._onDatesChange}
								onProductChange={this._onProductChange}
								onChangeTopTabs={this._onChangeTopTabs}
								disabled={this.state.disabled}
								handleMicroSurveyClick={this.handleMicroSurveyClick}
							/>
							<div className="app-main">
								<AppSideBar onChangeLeftMenu={this._onChangeLeftMenu} menuItems={gatesMenu.left} />
								<div className="app-main__outer">
									{
										<Analytics
											topTab={topTab}
											topTabName={
												topTab !== null && topTab !== undefined
													? gatesMenu.top[topTab].label.toLowerCase()
													: null
											}
											leftTab={this.state.leftTab ? this.state.leftTab.toLowerCase() : null}
											monitoring={this.state.top.isToggleOn}
											VoronoiClicked={selectedVoronoi => this.VoronoiClicked(selectedVoronoi)}
										/>
									}
								</div>
							</div>
						</div>
						<ScrollUpButton />
					</Fragment>
				)}
			/>
		);
	}
}

const mapStateToProp = state => ({
	colorScheme: state.ThemeOptions.colorScheme,
	enableFixedHeader: state.ThemeOptions.enableFixedHeader,
	enableMobileMenu: state.ThemeOptions.enableMobileMenu,
	enableFixedFooter: state.ThemeOptions.enableFixedFooter,
	enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
	enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
	enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt
});

export default withRouter(connect(mapStateToProp)(GatesLayout));
