import avatar1 from "../../architectuiassets/utils/images/avatars/1.jpg";
import avatar2 from "../../architectuiassets/utils/images/avatars/2.jpg";
import avatar3 from "../../architectuiassets/utils/images/avatars/3.jpg";
import avatar4 from "../../architectuiassets/utils/images/avatars/4.jpg";

export const Submissions = {
	All: [
		{
			id: 1,
			name: "Ali Anwar",
			role: "Enumerator",
			district: "Malir",
			status: "Approved",
			facilityCenter: "Medicos Healtcare",
			dos: "20-04-2020",
			profilePhoto: avatar1,
			title: "Sayana Press Submission",
			resubmission: true
		},
		{
			id: 2,
			name: "Ali Anwar",
			role: "Enumerator",
			district: "Korangi",
			status: "Approved",
			facilityCenter: "Medicos Healtcare",
			dos: "20-04-2020",
			profilePhoto: avatar2,
			title: "Sayana Press Submission",
			resubmission: true
		},
		{
			id: 3,
			name: "Ali Anwar",
			role: "Enumerator",
			district: "Thatta",
			status: "Approved",
			facilityCenter: "Medicos Healtcare",
			dos: "20-04-2020",
			profilePhoto: avatar3,
			title: "Sayana Press Submission"
		},
		{
			id: 4,
			name: "Ali Anwar",
			role: "Enumerator",
			district: "Tharparkar",
			status: "Approved",
			facilityCenter: "Medicos Healtcare",
			dos: "20-04-2020",
			profilePhoto: avatar4,
			title: "Sayana Press Submission"
		},
		{
			id: 5,
			name: "Ali Anwar",
			role: "Enumerator",
			district: "Larkana",
			status: "Approved",
			facilityCenter: "Medicos Healtcare",
			dos: "20-04-2020",
			profilePhoto: avatar1,
			title: "Sayana Press Submission",
			resubmission: true
		},
		{
			id: 6,
			name: "Ali Anwar",
			role: "Enumerator",
			district: "Hyderabad",
			status: "Approved",
			facilityCenter: "Family Planning Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar2,
			title: "Sayana Press Submission"
		}
	],
	Malir: [
		{
			id: 1,
			name: "Iftikhar Ahmed",
			role: "Enumerator",
			district: "Malir",
			status: "Approved",
			facilityCenter: "Family Planning Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar1,
			title: "Sayana Press Submission"
		},
		{
			id: 2,
			name: "Iftikhar Ahmed",
			role: "Enumerator",
			district: "Malir",
			status: "Approved",
			facilityCenter: "Family Planning Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar2,
			title: "Sayana Press Submission"
		},
		{
			id: 3,
			name: "Iftikhar Ahmed",
			role: "Enumerator",
			district: "Malir",
			status: "Approved",
			facilityCenter: "Family Planning Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar3,
			title: "Sayana Press Submission"
		},
		{
			id: 4,
			name: "Iftikhar Ahmed",
			role: "Enumerator",
			district: "Malir",
			status: "Approved",
			facilityCenter: "Family Planning Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar4,
			title: "Sayana Press Submission"
		},
		{
			id: 5,
			name: "Iftikhar Ahmed",
			role: "Enumerator",
			district: "Malir",
			status: "Approved",
			facilityCenter: "Family Planning Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar1,
			title: "Sayana Press Submission"
		},
		{
			id: 6,
			name: "Iftikhar Ahmed",
			role: "Enumerator",
			district: "Malir",
			status: "Approved",
			facilityCenter: "Family Planning Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar2,
			title: "Sayana Press Submission"
		}
	],
	Korangi: [
		{
			id: 1,
			name: "Shahzad Aslam",
			role: "Enumerator",
			district: "Korangi",
			status: "Approved",
			facilityCenter: "Dignity Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar1,
			title: "Sayana Press Submission"
		},
		{
			id: 2,
			name: "Shahzad Aslam",
			role: "Enumerator",
			district: "Korangi",
			status: "Approved",
			facilityCenter: "Dignity Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar2,
			title: "Sayana Press Submission"
		},
		{
			id: 3,
			name: "Shahzad Aslam",
			role: "Enumerator",
			district: "Korangi",
			status: "Approved",
			facilityCenter: "Dignity Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar3,
			title: "Sayana Press Submission"
		},
		{
			id: 4,
			name: "Shahzad Aslam",
			role: "Enumerator",
			district: "Korangi",
			status: "Approved",
			facilityCenter: "Dignity Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar4,
			title: "Sayana Press Submission"
		},
		{
			id: 5,
			name: "Shahzad Aslam",
			role: "Enumerator",
			district: "Korangi",
			status: "Approved",
			facilityCenter: "Dignity Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar1,
			title: "Sayana Press Submission"
		},
		{
			id: 6,
			name: "Shahzad Aslam",
			role: "Korangi",
			district: "Hyderabad",
			status: "Approved",
			facilityCenter: "Dignity Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar2,
			title: "Sayana Press Submission"
		}
	],
	Thatta: [
		{
			id: 1,
			name: "Shahnawaz Khan",
			role: "Enumerator",
			district: "Thatta",
			status: "Approved",
			facilityCenter: "Medina Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar1,
			title: "Sayana Press Submission"
		},
		{
			id: 2,
			name: "Shahnawaz Khan",
			role: "Enumerator",
			district: "Thatta",
			status: "Approved",
			facilityCenter: "Medina Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar2,
			title: "Sayana Press Submission"
		},
		{
			id: 3,
			name: "Shahnawaz Khan",
			role: "Enumerator",
			district: "Thatta",
			status: "Approved",
			facilityCenter: "Medina Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar3,
			title: "Sayana Press Submission"
		},
		{
			id: 4,
			name: "Shahnawaz Khan",
			role: "Enumerator",
			district: "Thatta",
			status: "Approved",
			facilityCenter: "Medina Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar4,
			title: "Sayana Press Submission"
		},
		{
			id: 5,
			name: "Shahnawaz Khan",
			role: "Enumerator",
			district: "Thatta",
			status: "Approved",
			facilityCenter: "Medina Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar1,
			title: "Sayana Press Submission"
		},
		{
			id: 6,
			name: "Shahnawaz Khan",
			role: "Enumerator",
			district: "Thatta",
			status: "Approved",
			facilityCenter: "Medina Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar2,
			title: "Sayana Press Submission"
		}
	],
	"Shaheed Benazirabad": [
		{
			id: 1,
			name: "Tahir Shah",
			role: "Enumerator",
			district: "Shaheed Benazirabad",
			status: "Approved",
			facilityCenter: "Medicos Healtcare",
			dos: "20-04-2020",
			profilePhoto: avatar1,
			title: "Sayana Press Submission"
		},
		{
			id: 2,
			name: "Tahir Shah",
			role: "Enumerator",
			district: "Shaheed Benazirabad",
			status: "Approved",
			facilityCenter: "Familia Medical",
			dos: "20-04-2020",
			profilePhoto: avatar2,
			title: "Sayana Press Submission"
		},
		{
			id: 3,
			name: "Tahir Shah",
			role: "Enumerator",
			district: "Shaheed Benazirabad",
			status: "Approved",
			facilityCenter: "Familia Medical",
			dos: "20-04-2020",
			profilePhoto: avatar3,
			title: "Sayana Press Submission"
		},
		{
			id: 4,
			name: "Tahir Shah",
			role: "Enumerator",
			district: "Shaheed Benazirabad",
			status: "Approved",
			facilityCenter: "Familia Medical",
			dos: "20-04-2020",
			profilePhoto: avatar4,
			title: "Sayana Press Submission"
		},
		{
			id: 5,
			name: "Tahir Shah",
			role: "Enumerator",
			district: "Shaheed Benazirabad",
			status: "Approved",
			facilityCenter: "Familia Medical",
			dos: "20-04-2020",
			profilePhoto: avatar1,
			title: "Sayana Press Submission"
		},
		{
			id: 6,
			name: "Tahir Shah",
			role: "Enumerator",
			district: "Shaheed Benazirabad",
			status: "Approved",
			facilityCenter: "Familia Medical",
			dos: "20-04-2020",
			profilePhoto: avatar2,
			title: "Sayana Press Submission"
		}
	],
	Larkana: [
		{
			id: 1,
			name: "Subhan Ahmed",
			role: "Enumerator",
			district: "Larkana",
			status: "Approved",
			facilityCenter: "Bismilla Medical",
			dos: "20-04-2020",
			profilePhoto: avatar1,
			title: "Sayana Press Submission"
		},
		{
			id: 2,
			name: "Subhan Ahmed",
			role: "Enumerator",
			district: "Larkana",
			status: "Approved",
			facilityCenter: "Bismilla Medical",
			dos: "20-04-2020",
			profilePhoto: avatar2,
			title: "Sayana Press Submission"
		},
		{
			id: 3,
			name: "Subhan Ahmed",
			role: "Enumerator",
			district: "Larkana",
			status: "Approved",
			facilityCenter: "Bismilla Medical",
			dos: "20-04-2020",
			profilePhoto: avatar3,
			title: "Sayana Press Submission"
		},
		{
			id: 4,
			name: "Subhan Ahmed",
			role: "Enumerator",
			district: "Larkana",
			status: "Approved",
			facilityCenter: "Bismilla Medical",
			dos: "20-04-2020",
			profilePhoto: avatar4,
			title: "Sayana Press Submission"
		},
		{
			id: 5,
			name: "Subhan Ahmed",
			role: "Enumerator",
			district: "Larkana",
			status: "Approved",
			facilityCenter: "Bismilla Medical",
			dos: "20-04-2020",
			profilePhoto: avatar1,
			title: "Sayana Press Submission"
		},
		{
			id: 6,
			name: "Subhan Ahmed",
			role: "Enumerator",
			district: "Larkana",
			status: "Approved",
			facilityCenter: "Bismilla Medical",
			dos: "20-04-2020",
			profilePhoto: avatar2,
			title: "Sayana Press Submission"
		}
	],
	Hyderabad: [
		{
			id: 1,
			name: "Allah Ditta",
			role: "Enumerator",
			district: "Hyderabad",
			status: "Approved",
			facilityCenter: "Fast Lane Medicals",
			dos: "20-04-2020",
			profilePhoto: avatar1,
			title: "Sayana Press Submission"
		},
		{
			id: 2,
			name: "Allah Ditta",
			role: "Enumerator",
			district: "Hyderabad",
			status: "Approved",
			facilityCenter: "Fast Lane Medicals",
			dos: "20-04-2020",
			profilePhoto: avatar2,
			title: "Sayana Press Submission"
		},
		{
			id: 3,
			name: "Allah Ditta",
			role: "Enumerator",
			district: "Hyderabad",
			status: "Approved",
			facilityCenter: "Fast Lane Medicals",
			dos: "20-04-2020",
			profilePhoto: avatar3,
			title: "Sayana Press Submission"
		},
		{
			id: 4,
			name: "Allah Ditta",
			role: "Enumerator",
			district: "Hyderabad",
			status: "Approved",
			facilityCenter: "Fast Lane Medicals",
			dos: "20-04-2020",
			profilePhoto: avatar4,
			title: "Sayana Press Submission"
		},
		{
			id: 5,
			name: "Allah Ditta",
			role: "Enumerator",
			district: "Hyderabad",
			status: "Approved",
			facilityCenter: "Fast Lane Medicals",
			dos: "20-04-2020",
			profilePhoto: avatar1,
			title: "Sayana Press Submission"
		},
		{
			id: 6,
			name: "Allah Ditta",
			role: "Enumerator",
			district: "Hyderabad",
			status: "Approved",
			facilityCenter: "Fast Lane Medicals",
			dos: "20-04-2020",
			profilePhoto: avatar2,
			title: "Sayana Press Submission"
		}
	],
	Tharparkar: [
		{
			id: 1,
			name: "Syed Bukhari",
			role: "Enumerator",
			district: "Tharparkar",
			status: "Approved",
			facilityCenter: "South Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar1,
			title: "Sayana Press Submission"
		},
		{
			id: 2,
			name: "Syed Bukhari",
			role: "Enumerator",
			district: "Tharparkar",
			status: "Approved",
			facilityCenter: "South Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar2,
			title: "Sayana Press Submission"
		},
		{
			id: 3,
			name: "Syed Bukhari",
			role: "Enumerator",
			district: "Tharparkar",
			status: "Approved",
			facilityCenter: "South Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar3,
			title: "Sayana Press Submission"
		},
		{
			id: 4,
			name: "Syed Bukhari",
			role: "Enumerator",
			district: "Tharparkar",
			status: "Approved",
			facilityCenter: "South Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar4,
			title: "Sayana Press Submission"
		},
		{
			id: 5,
			name: "Syed Bukhari",
			role: "Enumerator",
			district: "Tharparkar",
			status: "Approved",
			facilityCenter: "South Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar1,
			title: "Sayana Press Submission"
		},
		{
			id: 6,
			name: "Syed Bukhari",
			role: "Enumerator",
			district: "Tharparkar",
			status: "Approved",
			facilityCenter: "South Medical Center",
			dos: "20-04-2020",
			profilePhoto: avatar2,
			title: "Sayana Press Submission"
		}
	]
};
